/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */
import 'metro-ui/build/css/metro.min.css';
import 'metro-ui/build/css/metro-icons.min.css';
// any CSS you require will output into a single css file (app.scss in this case)
import '../css/app.scss';

import $ from 'jquery';

import 'metro-ui';
import Blazy from 'blazy';
